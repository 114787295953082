/*@import '../../vendor/filament/forms/dist/module.esm.css';*/

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}


